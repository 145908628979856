.questionContainer, .instructionQuestionWrapper {
    border-top: 2px solid #6f6f6f;
}
.lastLine:last-child > div, .instructionQuestionWrapper {
    border-bottom: 2px solid #6f6f6f;
}

.group {
    border-radius: 13px;
    background-color: #ededed;
}

.circleBorder, .circleBorderInstruction {
    border: 3px solid #ededed;
    border-radius: 50%;
    padding: 6px;
}

.answerSpan {
    text-align: left;
    text-indent: -8px;
    margin-left: 6px;
    margin-right: 8px;
}

.instructionHeaderText, .instructionHeaderTextSmall {
    display: block;
    font-size: 16px;
    font-weight: normal;
    line-height: 22px;
    margin-bottom: 9px;
}

.instructionHeaderTextSmall {
    font-size: 15px;
    margin-bottom: 15px;
}

.testButton {
    pointer-events: none;
}

.errorWrapper {
    position: absolute;
    left: 15px;
    bottom: 0;
    font-weight: bold;
}

.invalid .title {
    color: var(--danger);
}
.invalid .questionContainer {
    color: var(--danger);
}

@media (max-width: 767px) {
    .buttonDescription {
        margin-left: 5px;
    }

    .buttonDescription::before {
        content: "- "
    }
}

@media print {
    .questionContainer {
        padding: 0.8rem 0 0.5rem !important;
    }

    .groupContainer {
        padding: 1rem 0 !important;
    }

    .groupContainer span {
        font-size: 0.9rem;
        line-height: 1.4 !important;
        display: block;
    }
}