@media print {
    .wrapper {
        padding-top: 6px !important;
        padding-bottom: 6px !important;
    }

    .text {
        font-size: 0.9rem;
        line-height: 1.15em;
    }
}