.progress {
    height: 5px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.bar1 {
    animation: loadingBar1 3s infinite;
}
.bar2 {
    animation: loadingBar2 3s infinite;
}

@keyframes loadingBar1 {
    0% {width: 0}
    50% {width: 100%}
    100% {width: 0}
}

@keyframes loadingBar2 {
    0% {width: 0}
    50% {width: 0}
    100% {width: 100%}
}
