.logosSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900px;
  margin: auto;
}

.logosSection > img {
  width: 100%;
}