/* standard number button */
.button {
    padding: 6px;
    height: 40px;
    min-width: 40px;
    line-height: 15px;
    margin: 1px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textButton {
    padding: 6px 12px;
    height: 36px;
    border-radius: 8px;
    font-size: .8rem;
    line-height: .7rem;
}

.buttonGroup {
    padding: 20px 54px 16px;
    border-radius: 20px;
    width: 100%;
}

.input:focus ~ .button {
    box-shadow: 0 0 4px 4px orange;
}
.button:active {
    color: #343a40 !important;
    background-color: transparent !important;
}
.button:hover, .input:checked ~ .button:active {
    color: #fff !important;
    background-color: #343a40 !important;
}

.input:checked ~ .button {
    color: white;
    background: black;
}
/* e/o standard number button */

/* plain text */
.plainText {
    padding: 8px;
    line-height: 15px;
    margin: 1px;
    font-style: italic;
}

.input:focus ~ .plainText {
    text-decoration: underline;
}
.plainText:active {
    text-decoration: none;
}
.plainText:hover, .input:checked ~ .plainText:active {
    text-decoration: underline;
}
.input:checked ~ .plainText {
    font-weight: bold;
}
/* e/o plain text */

/* height contrast button */
.heightContrastButton, .input:disabled:not(:checked) ~ .heightContrastButton {
    background: yellow;
    border: 2px solid black;
    font-style: normal;
    color: black;
    font-weight: 600;
    border-radius: 6px;
    padding: 10px;
}

.input:focus ~ .heightContrastButton {
    text-decoration: underline !important;
}
.heightContrastButton:active {
    text-decoration: none;
}
.heightContrastButton:hover, .input:checked ~ .heightContrastButton:active {
    text-decoration: underline;
}
/* e/o height contrast button */

.button:global(.disabled), .plainText:global(.disabled) {
    cursor: default;
    pointer-events: none;
}

@media print {
    .input ~ .button {
        color: black !important;
        background: transparent;
        border: 1px dashed #323232;

        height: 34px;
        min-width: 34px;
    }
    .input:checked ~ .button {
        border: 1px solid black;
        background-color: lightgray;
    }
    .input ~ .button span {
        font-size: 0.8rem;
        line-height: 0.75rem;
    }
    .input ~ .printSmall {
        height: 28px;
        min-width: 28px;
    }
}

.mini {
    height: 20px;
    min-width: 20px;
}

.inputMini ~ .button {
    font-size: 10px;
    opacity: 1;
}

.labelMini {
    margin-bottom: 1px;
}

/* fix window scroll on focus / submit error scroll */
.input {
    height: 40px;
}
/* e/o fix window scroll on focus / submit error scroll */
