/* style arrow select on firefox */
.select {
  min-width: 54px;
  max-width: 60px;
}

.select:focus {
  outline: none;
  box-shadow: 0 0 2px 2px orange;
  border-color: black;
}

@-moz-document url-prefix() {
  .select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("arrow.png") right / 26px no-repeat #fff;
    padding-right: 26px;
    width: 58px;
  }
}

@media print {
  .wrapper {
    padding: .4rem 0 !important;
  }

  .select {
    height: 28px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}