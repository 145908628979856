.center {
    display: block;
    margin: auto;
}

.rounded {
    border-radius: 25px;
}

.border {
    border: 1px solid #444;
}

.m-l-10p {
    margin-left: 10%;
}

.width800 {
    width: 800px !important;
}
.width560 {
    width: 560px !important;
}
.width400 {
    width: 400px !important;
}
.width350 {
    width: 350px !important;
}
.width300 {
    width: 300px !important;
}
.width250 {
    width: 250px !important;
}
.width200 {
    width: 200px !important;
}