.anchor {
    position: absolute;
    z-index: 100;
    height: 1rem;
    margin-left: -2.5rem;
    white-space: nowrap;
    transform: rotate(90deg);
    transform-origin: 0 100%;
}
@media (max-width: 767px) {
    .anchor {
        margin-left: -2rem;
    }
}
.anchor > div {
    transform: rotate(180deg);
}

@media print {
    .anchor div {
        background-color: transparent !important;
        color: #6f6f6f !important;
        border: 1px solid #6f6f6f;
    }
}
