.layout {
    min-height: 100vh;
    min-width: 800px;
    background: white;
}

.content {
    margin: 1rem auto;
    width: 100%;
}

/* use strong tag in TextContent tr=ext in json to hide element on highContrast version */
.highContrast strong {
    display: none;
}

@media (max-width: 767px) {
    .layout {
        min-width: unset;
        max-width: unset;
    }
}

@media print {
    .footer {
        display: none;
    }

    .layout {
        display: block !important;
        color-adjust: exact;
        -webkit-print-color-adjust: exact;
    }
}
