.pageBreak {
    float: none;
    clear: both;
    margin-top: 10px;
    overflow: hidden;
}
.divider {
    width: 100%;
    border-bottom: 1px dashed #dee2e6;
}
.text {
    color: #dee2e6;
    text-align: center;
    margin: 0 auto;
    font-size: .8rem;
}

:global(body) {
    overflow: visible;
    display: block;
}

@media print {
    .text {
        display: none;
    }
    .divider {
        border: none;
    }
}
