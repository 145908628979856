.description {
    font-size: 14px;
    line-height: 1.15em;

    margin-top: 10px;
    margin-bottom: 12px;
}

@media only screen and (max-width: 596px) {
    .description {
        display: list-item;
        list-style-type: none;
        font-size: 1rem;

        margin-left: 60px;
        margin-top: -32px;
        margin-bottom: 12px;
    }
}