.buttonWrapper {
    display: inline-block;
}

.button {
    cursor: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='1.8em' height='1.8em' viewBox='64 64 896 896' style='enable-background:new 0 0 512 512;' xml:space='preserve'%3E %3Cpath d='M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 000-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 009.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z'/%3E %3Cpath d='M761.1 288.3L687.8 215 325.1 577.6l-15.6 89 88.9-15.7z' fill='white'/%3E %3C/svg%3E") 10 20, pointer !important;

    background: transparent;
    border: none;
    padding: 0;
    margin: 0;

    pointer-events: auto;
    text-align: inherit;
    font-weight: inherit;

    transition: opacity .25s;
}

.button:hover, .button:focus {
    opacity: .75;
}

.modalWrapper {
    position: absolute;
    top: 45%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin: 20px -50% 20px 0;
    transform: translate(-50%, -50%);
    padding: 0;
    border: none;
}

.modalDialog {
    margin: 0 auto;
    max-width: 80vw;
    width: 560px;
}

.modalDialog {
    transition: filter .25s;
}

.modalContentLoading {
    filter: grayscale(80%);
    pointer-events: none;
}

.modalBody {
    max-height: 70vh;
    overflow: scroll;
}