/*
commented lines for skew version
*/

.row {
    height: 100%;
    display: flex;
}

.wrapper {
    flex: 1 1 0;
    min-width: 0;

    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.span {
    line-height: 1em;
    padding: 0 6px;
    max-height: 120px;
    height: max-content;
}

.spanSkew {
    white-space: pre-line;
    writing-mode: vertical-rl;
    transform: rotateZ(225deg) translateY(100%);
    transform-origin: center bottom
}

.likertScaleQuestionWrapper {
    top: 0; /* to enable sticky position from bootstrap */
    z-index: 2;
}

/* tool edit page - writing-mode not working on buttons in chrome  */
.spanSkew span {
    white-space: pre-line !important;
    writing-mode: vertical-rl;
}

@media print {
    .span {
        line-height: 0.9em;
        font-size: 70%;
    }
}

.line {
    position: absolute;
    bottom: -16px;
    left: 50%;
    width: 1px;
    height: 10px;
    background-color: #777;
}

@media print {
    .likertScaleQuestionWrapper {
        border-top: none !important;
        margin-top: 0 !important;
    }
}