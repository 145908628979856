.group {
    border-radius: 35px;
    background-color: #ededed;
}

.instructionHeader {
    padding: 36px;
}

.testAnswer {
    width: 45%;
}

.instructionHeaderText {
    display: block;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 9px;
    padding: 0 6px;
}

.questionContainer {
    border: 2px solid black;
    border-radius: 40px;
    box-shadow: 3px 3px black;
}

.questionContainerCompact {
    border-top: 2px solid #5d5d5d;
}

.ordinalNumber {
    background-color: white;
    border: 2px solid black;
    border-radius: 40px;
    padding: 5px 13px;
    display: inline;
    position: relative;
    top: 12px;
    font-weight: bold;
}

.arrows {
    width: 30%;
}

.testButton {
    pointer-events: none;
}

.answerSpan {
    text-align: left;
    text-indent: -8px;
    margin-left: 6px;
    margin-right: 8px;
}

.errorWrapper {
    position: absolute;
    left: 15px;
    bottom: -30px;
    font-weight: bold;
}

.invalid .questionContainer {
    border-color: var(--danger);
    box-shadow: 3px 3px var(--danger);
}
.invalid .ordinalNumber {
    border-color: var(--danger);
    color: var(--danger);
}

@media (max-width: 767px) {
    .testAnswer {
        width: 100%;
    }

    .buttonDescription {
        margin-left: 5px;
    }

    .buttonDescription::before {
        content: "- "
    }

    .img {
        width: 120%;
        max-width: 400px;
    }
}

.femaleImg, .maleImg {
    max-width: 100%;
}

.answerImg {
    width: 300px;
    max-width: 100%;
    border-radius: 25px;
    margin: auto;
}

@media print {
    .instructionHeader {
        padding: 14px;
    }

    .instructionHeaderText {
        display: block;
        font-size: 15px;
        line-height: 17px;
        margin-bottom: 5px;
        padding: 0 3px;
    }

    .femaleImg, .maleImg {
        max-width: 80%;
    }

    .testQuestionWrapper {
        margin-top: 0 !important;
    }

    .testButton {
        width: 36px !important;
        height: 36px !important;
    }

    .group {
       padding-top: 0.5rem !important;
       padding-bottom: 0.5rem !important;
    }

    .answerImg {
        width: 280px;
    }

    .testAnswer * {
        font-size: 15px;
        line-height: 17px;
    }
}