.buttonDescription {
    margin-left: 5px;
}

.buttonDescription::before {
    content: "- "
}

.errorWrapper {
    position: absolute;
    left: 15px;
    bottom: 0;
    font-weight: bold;
}

.invalid .title {
    color: var(--danger);
}
.invalid .questionContainer {
    color: var(--danger);
}
