.textarea {
    display: none;
}

.editor, .toolbar {
    padding: 5px;
    border: 1px solid #bfbfbf;
}

.editor {
    padding: 10px;
    min-height: 240px;
}

.toolbar {
    padding-bottom: 0;
}

.editor * {
    color: #2e3136 !important;
    margin: 0 0 0.45em !important;
    line-height: 1.45em;
}

.editor ul {
    margin-left: 17px !important;
}

.editor ol {
    margin-left: 22px !important;
}

.editor *:focus {
    outline: none !important;
}
