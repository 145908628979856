.wrapper {
    background-color: #ededed;
}

.draggingOverwrapper {
    background-color: rgb(193, 193, 193);
}

.button:hover, .button:focus {
    outline: none;
    box-shadow: 0 0 2px 2px orange;
}
.buttonDragging {
    box-shadow: 0 0 4px 4px orange;
}

.errorWrapper {
    position: absolute;
    left: 15px;
    bottom: 0;
    font-weight: bold;
}

.invalid .title {
    color: var(--danger);
}
.invalid .questionContainer {
    color: var(--danger);
}