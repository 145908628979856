.button {
    width: 38px;
    height: 38px;
    margin: 1px;
    background-color: #ededed;
}

.compactButton {
    width: 23px;
    height: 25px;
    border-radius: 5px;
    background-color: transparent;
}

.childStructuredButton {
    border-radius: 14px !important;
}

.button:global(.disabled) {
    cursor: default;
}

.input:focus ~ .button {
    box-shadow: 0 0 4px 4px orange;
}

.input:checked ~ .button {
    color: white;
    background: black;
}

/* height contrast button */
.heightContrastButton, .input:disabled:not(:checked) ~ .heightContrastButton {
    background: yellow;
    border: 2px solid black;
    font-style: normal;
    color: black;
    font-weight: 600;
    border-radius: 6px;
    padding: 8px 12px;
    width: unset;
    height: unset;
    min-width: 44px;
    min-height: 44px;
}

.input:focus ~ .heightContrastButton {
    text-decoration: underline !important;
}
.heightContrastButton:active {
    text-decoration: none;
}
.heightContrastButton:hover, .input:checked ~ .heightContrastButton:active {
    text-decoration: underline;
}
/* e/o height contrast button */

@media print {
    .input ~ .button {
        color: black !important;
        border: 1px dashed black;
    }
    .input:checked ~ .button {
        border: 1px solid black;
        background: lightgray;
    }
    :global(.btn.disabled) {
        opacity: 1 !important;
    }
}